<template>
  <PageWrapper>
    <PagePanel>
      <PageSearchArea @queryData="queryIbReportData">
        <template #form>
          <el-form label-position="top">
            <AccountNumber ref="accountNumber" @accountCallback="queryIbReportData"></AccountNumber>
            <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>
    <el-row :gutter="16" class="piece_list">
      <el-col :xg="24" :lg="6" :xl="6" v-for="item in pieceData" :key="item.index">
        <div
          :class="['piece_item', { active: selectedCard === item.value }]"
          :data-testid="item.value"
          @click="onChangePiece(item)"
        >
          <div class="item_top">
            <img :src="require(`@/assets/images-1/home/${item.img}.png`)" alt="" />
            <span>{{ $t(`ibReport.${item.label}`) }}</span>
          </div>
          <div class="item_bottom">
            <font class="reduced-font-size" v-if="item.value === 'openedAccounts'">{{
              cardData[item.value].value | currency('', 0)
            }}</font>
            <span v-else>
              <span class="bottom_currency">{{ currAccountCurrencyFilter }}</span>
              <font class="reduced-font-size">{{ cardData[item.value].value | currency('') }}</font>
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
    <PagePanel :tabs="tableTab" :tabActive.sync="tabActive" class="table_panel">
      <section v-show="['netFunding', 'deposits'].indexOf(selectedCard) >= 0 && tabActive === 'deposits'">
        <div class="table_search">
          <el-form label-position="top">
            <el-form-item :label="$t('home.Account')">
              <el-input v-model="depositAccountNumber" auto-complete="off" type="text"/>
            </el-form-item>
            <el-form-item :label="$t('home.Name')">
              <el-input v-model="depositAccountName" auto-complete="off" type="text" />
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="searchDepositData">{{$t('common.field.query')}}</el-button>
        </div>
        <el-table
          :data="depositDisplayData"
          style="width: 100%"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          @sort-change="sortChange"
          ref="depositTable"
        >
          <el-table-column :label="$t('common.keys.DATE')" min-width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime | formatDate('DD/MM/YYYY') }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('home.Account')" prop="mt4Account" min-width="120"></el-table-column>
          <el-table-column :label="$t('home.Name')" prop="realName" min-width="120"></el-table-column>
          <el-table-column :label="$t('common.keys.method')" min-width="120">
            <template slot-scope="scope">
              <span>{{ showDepositMethod(scope.row.paymentType, scope.row.paymentChannel) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.keys.currency')" prop="currency" min-width="120"></el-table-column>
          <el-table-column
            prop="amount"
            :label="$t('ibReport.depositAmount')"
            min-width="120"
            sortable="custom"
            sort-by="amount"
            :sort-orders="['ascending', 'descending']"
            class-name="depositTable"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.amount | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table>
        <FakePagination :raw-data="depositTableData" :display-data.sync="depositDisplayData"></FakePagination>
      </section>

      <section v-show="['netFunding', 'withdraw'].indexOf(selectedCard) >= 0 && tabActive === 'withdraw'">
        <div class="table_search">
          <el-form label-position="top">
            <el-form-item :label="$t('home.Account')">
              <el-input v-model="withdrawAccountNumber" auto-complete="off" type="text"/>
            </el-form-item>
            <el-form-item :label="$t('home.Name')">
              <el-input v-model="withdrawAccountName" auto-complete="off" type="text" />
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="searchWithdrawData">{{$t('common.field.query')}}</el-button>
        </div>
        <el-table
          :data="withdrawDisplayData"
          style="width: 100%"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          @sort-change="sortChange"
          ref="withdrawTable"
        >
          <el-table-column :label="$t('common.keys.DATE')" min-width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime | formatDate('DD/MM/YYYY') }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('home.Account')" prop="mt4Account" min-width="120"></el-table-column>
          <el-table-column :label="$t('home.Name')" prop="realName" min-width="120"></el-table-column>
          <el-table-column :label="$t('common.keys.method')" min-width="120">
            <template slot-scope="scope">
              <span>{{ showWithdrawMethod(scope.row.withdrawType, scope.row.transferCountry) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.keys.currency')" prop="currency" min-width="120"></el-table-column>
          <el-table-column
            prop="amount"
            :label="$t('ibReport.withdrawalAmount')"
            min-width="120"
            sortable="custom"
            sort-by="amount"
            :sort-orders="['ascending', 'descending']"
            class-name="withdrawTable"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.amount | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table>
        <FakePagination :raw-data="withdrawTableData" :display-data.sync="withdrawDisplayData"></FakePagination>
      </section>

      <section v-if="selectedCard == 'openedAccounts'">
        <el-table :data="openedAccountsDisplayData" style="width: 100%" @sort-change="sortChange">
          <el-table-column :label="$t('common.keys.DATE')" min-width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.approvedDate | date('DD/MM/YYYY') }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.keys.ACCNUM')" prop="mt4Account" min-width="140"></el-table-column>
          <el-table-column :label="$t('common.keys.NAME')" prop="name" min-width="120"></el-table-column>
          <el-table-column :label="$t('common.keys.EMAIL')" prop="email" min-width="130"></el-table-column>
          <el-table-column
            prop="type"
            :label="$t('common.keys.ACCTYPE')"
            sortable
            sort-by="type"
            min-width="170"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              <span> {{ $config.accountTypeMaps[scope.row.type] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="platform"
            :label="$t('common.keys.PLATFORM')"
            sortable
            sort-by="platform"
            :min-width="130"
            :sort-orders="['ascending', 'descending']"
          ></el-table-column>
          <el-table-column
            prop="currency"
            :label="$t('common.keys.BASECURRENCY')"
            sortable
            sort-by="currency"
            :min-width="170"
            :sort-orders="['ascending', 'descending']"
          ></el-table-column>
          <el-table-column
            prop="balance"
            :label="$t('common.keys.BALANCE')"
            sortable
            sort-by="balance"
            :min-width="150"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.currency | currencySymbol }}{{ scope.row.balance | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table>
        <FakePagination :raw-data="openedAccountsTableData" :display-data.sync="openedAccountsDisplayData"></FakePagination>
      </section>
    </PagePanel>
  </PageWrapper>
</template>

<script>
import AccountNumber from '@/components/customUI/AccountNumber';
import DateRangePicker from '@/components/customUI/DateRangePicker';
import FakePagination from '@/components/elementUI/Pagination/FakePagination';
import { apiQueryIbReportData } from '@/resource';
export default {
  name: 'IbReport',
  components: {
    AccountNumber,
    DateRangePicker,
    FakePagination
  },
  data() {
    return {
      userID: this.$store.state.common.CUID,
      startDate: '',
      endDate: '',
      cardData: {
        netFunding: { label: 'NET FUNDING', value: 0 },
        deposits: { label: 'DEPOSITS', value: 0 },
        withdraw: { label: 'WITHDRAW', value: 0 },
        openedAccounts: { label: 'OPENED ACCOUNTS', value: 0 }
      },
      selectedCard: '',
      fundingTableData: [],
      fundingDisplayData: [],
      openedAccountsTableData: [],
      openedAccountsDisplayData: [],
      currAccountCurrencyFilter: '',
      depositTableData: [],
      regularDepositTableData: [],
      depositDisplayData: [],
      withdrawTableData: [],
      regularWithdrawTableData: [],
      withdrawDisplayData: [],
      depositAccountName: '',
      depositAccountNumber: '',
      withdrawAccountName: '',
      withdrawAccountNumber: '',
      depositMethods: {
        1: 'Credit/Debit Card', // 信用卡
        2: {
          1: 'Bank Wire Transfer (International)', // 银行汇款（国际）
          2: 'Bank Wire Transfer (Australia)', // 银行汇款（澳大利亚）
          3: 'Skrill / Moneybookers', // skrill
          4: 'Neteller', // neteller
          5: 'Bpay', // Bpay
          6: 'POLI Payment', // Poli Payment
          7: 'Broker to Broker Transfer', // Broker To Broker Transfer
          8: 'Wechat Pay' // Wechat Pay  微信支付
        }, // 线下支付 "Offline Payment"
        3: 'Union Pay', // 银联
        4: 'Internet Banking (Thailand)', //泰国入金方式，针对泰国用户使用
        5: 'Internet Banking (Malaysia)', // 马来西亚入金方式，针对泰国用户使用
        6: 'Quick Payment', // 快捷支付
        7: 'FasaPay', // FasaPay
        8: 'Internet Banking (Vietnam)', //越南专用zotaPay
        9: 'Mobile Pay', //移动支付
        10: 'AppPay', // AppPay
        11: 'Neteller', // Neteller
        12: 'Internet Banking (Nigeria)', // Nigeria
        13: 'UnionPay Transfer', // UnionPay2
        14: 'Paypal', // Paypal
        15: {
          1: 'cryptobitcoin',
          2: 'cryptotetheromni',
          3: 'cryptotethererc20',
          4: 'cryptotethererc20',
          5: 'cryptotetheromni',
          6: 'cryptobitcoin',
          7: 'cryptotethertrc20'
        },
        16: 'Canadian Local Bank Transfer',
        17: 'SticPay',
        18: 'Internet Banking (Indonesia)', //印尼入金
        19: 'South Korea Bank Transfer',
        20: 'Bitwallet',
        21: {
          1: 'Skrill / Moneybookers',
          2: 'Neteller'
        },
        22: 'trustly',
        23: 'polipayment',
        24: { 1: 'Internet Banking (India)', 2: 'India Local Bank Wire Transfer', 3: 'Internet Banking (India)' },
        25: 'Internet Banking (Philippine)',
        27: 'astropay',
        29: 'Internet Banking (South Africa)',
        32: 'Vload',
        40: 'Internet Banking (Uganda)',
        41: 'Internet Banking (Rwanda)',
        42: 'Internet Banking (Zambia)',
        43: 'Internet Banking (Congo)',
        44: 'Internet Banking (Cameroon)',
        45: 'Internet Banking (Burundi)',
        46: 'Internet Banking (Kenya)',
        47: 'Internet Banking (Ghana)',
        48: 'Internet Banking (Tanzania)'
      },
      withdrawMethods: this.$config.withdrawMethods(this.$store.state.common.regulator),

      pieceData: [
        { label: 'netFunding', value: 'netFunding', img: 'NET_FUNDING' },
        { label: 'deposits', value: 'deposits', img: 'DEPOSITS' },
        { label: 'withdraw', value: 'withdraw', img: 'WITHDRAW' },
        { label: 'openedAccs', value: 'openedAccounts', img: 'OPEND_ACCOUNT' }
      ],
      tabActive: null
    };
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.selectedCard = query.cardMethod || 'netFunding';
      },
      immediate: true
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    tableTab() {
      let result = [];
      const selected = this.selectedCard;
      if (selected === 'netFunding') {
        this.tabActive = 'deposits';
        result = [
          { name: 'deposits', label: this.$t('common.field.depositRecords') },
          { name: 'withdraw', label: this.$t('common.field.withdrawRecords') }
        ];
      } else {
        this.tabActive = selected;
        // TODO：下面国际化的时候可以做些优化
        let label = selected === 'deposits' ? this.$t('common.field.depositRecords') : selected === 'withdraw' ? this.$t('common.field.withdrawRecords') : this.$t('common.field.accountOpeningRecord');
        result = [{ name: this.selectedCard, label: label }];
      }
      return result;
    }
  },
  methods: {
    onChangePiece(obj) {
      this.selectedCard = obj.value;
    },

    queryIbReportData() {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }

      return apiQueryIbReportData({
        userId: this.userID,
        rebateAccount: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate
      }).then(resp => {
        this.depositAccountName = '';
        this.depositAccountNumber = '';
        this.withdrawAccountName = '';
        this.withdrawAccountNumber = '';
        this.$refs.depositTable.clearSort();
        this.$refs.withdrawTable.clearSort();

        const respData = resp.data;
        this.cardData.netFunding.value = respData.netFunding;
        this.cardData.deposits.value = respData.totalDeposit;
        this.cardData.withdraw.value = respData.totalWithdraw;
        this.cardData.openedAccounts.value = respData.openedAccounts;

        this.fundingTableData = respData.funding;
        this.openedAccountsTableData = respData.openedAccountsDetail;
        this.depositTableData = respData.deposits;
        this.regularDepositTableData = respData.deposits;
        this.withdrawTableData = respData.withdraws;
        this.regularWithdrawTableData = respData.withdraws;

        this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'balance';
      this.sorting.order = this.sorting.order || 'descending';
      this.sorting.tableName = this.sorting.tableName || 'openedAccountTable';
      if (this.sorting.tableName === 'depositTable') {
        this.depositTableData.sort((a1, a2) => {
          return this.sorting.order == 'descending'
            ? a2[this.sorting.column] - a1[this.sorting.column]
            : a1[this.sorting.column] - a2[this.sorting.column];
        });
      } else if (this.sorting.tableName === 'withdrawTable') {
        this.withdrawTableData.sort((a1, a2) => {
          return this.sorting.order == 'descending'
            ? a2[this.sorting.column] - a1[this.sorting.column]
            : a1[this.sorting.column] - a2[this.sorting.column];
        });
      } else {
        this.openedAccountsTableData.sort((a1, a2) => {
          return this.sorting.order == 'descending'
            ? a2[this.sorting.column] - a1[this.sorting.column]
            : a1[this.sorting.column] - a2[this.sorting.column];
        });
      }
    },
    sortChange(column, prop, order) {
      if (!column.column) {
        return;
      }
      this.sorting = {
        column: column.column.property,
        order: column.order,
        tableName: column.column.className
      };
      this.sortData();
    },
    searchDepositData() {
      this.depositTableData = this.regularDepositTableData.filter(
        deposit =>
          (!this.depositAccountNumber ||
            deposit.mt4Account
              .toString()
              .toLowerCase()
              .includes(this.depositAccountNumber.toLowerCase())) &&
          (!this.depositAccountName ||
            deposit.realName
              .toString()
              .toLowerCase()
              .includes(this.depositAccountName.toLowerCase()))
      );
    },
    searchWithdrawData() {
      this.withdrawTableData = this.regularWithdrawTableData.filter(
        withdraw =>
          (!this.withdrawAccountNumber ||
            withdraw.mt4Account
              .toString()
              .toLowerCase()
              .includes(this.withdrawAccountNumber.toLowerCase())) &&
          (!this.withdrawAccountName ||
            withdraw.realName
              .toString()
              .toLowerCase()
              .includes(this.withdrawAccountName.toLowerCase()))
      );
    },
    showDepositMethod(method, channel) {
      if (typeof this.depositMethods[method] === 'object') {
        return this.depositMethods[method][channel]
          ? this.$options.filters.methods(this.depositMethods[method][channel])
          : channel;
      } else {
        return this.depositMethods[method] ? this.$options.filters.methods(this.depositMethods[method]) : method;
      }
    },
    showWithdrawMethod(method, transferCountry) {
      if (typeof this.withdrawMethods[method] === 'object') {
        return this.withdrawMethods[method][transferCountry]
          ? this.$options.filters.methods(this.withdrawMethods[method][transferCountry])
          : transferCountry;
      } else {
        return this.withdrawMethods[method] ? this.$options.filters.methods(this.withdrawMethods[method]) : method;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.piece_list {
  // width: 100%;
  margin-top: 16px;
  .piece_item {
    width: 100%;
    padding: 24px;
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    .item_top {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      color: $text-secondary;
      font-size: 14px;
      img {
        width: 24px;
        @include rtl-sass-prop(margin-right, margin-left, 8px);
      }
    }
    .item_bottom {
      color: $text-primary;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      .bottom_currency {
        @include rtl-sass-prop(padding-right, padding-left, 5px);
      }
    }

    &:hover {
      box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.1);
    }
  }
  .active {
    border: 1px solid $primary;
  }
}
.table_panel {
  transition: all transform 2s;
}
.table_search {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .el-form {
    width: 100%;
    display: flex;
    align-items: center;
    .el-form-item {
      width: 240px;
      margin-bottom: 16px;
      @include rtl-sass-prop(margin-right, margin-left, 16px);
    }
  }
  .el-button {
    height: 32px;
    margin-bottom: 16px;
  }
  @include screen-mobile {
    flex-direction: column;
    .el-form {
      flex-direction: column;
      .el-form-item {
        width: 100%;
        @include rtl-sass-prop(margin-right, margin-left, 0px);
      }
    }
    .el-button {
      width: 100%;
    }
  }
}
</style>
